<template>
  <div class="container">
    <h1>{{ msg }}</h1>
    <p v-if="loading">
      Cargando la información de la factura electrónica, en breve podra visualizar el contenido la misma.
    </p>
    <v-progress-circular
      v-if="loading"
      color="primary"
      indeterminate
      :size="40"
      :width="5"
    ></v-progress-circular>
    <p v-if="fetchingError">
      {{ fetchingError }}
    </p>
  </div>
  <div v-if="showInvoice">
    <print-pos-fa :dataProps="print" :showInvoice="showInvoice"></print-pos-fa>
  </div>
</template>

<script>
import PrintPosFa from '@/components/PrintPosFa.vue';
//import data from '@/assets/data.json';

export default {
  name: 'HomePage',
  props: {
    msg: String
  },
  components: {
    PrintPosFa
  },
  data() {
    return {
      loading: true,
      data: {},
      print: {},
      showInvoice: false,
      fetchingError: '',
      invoiceStatus: ["Pendiente de pago", "Cancelada", "Crédito"],
      paymentMethod: ["Pendiente", "Efectivo", "Cheque", "Transferencia", "Tarjeta de Crédito", "Otro"],
      days: ["8 Dias", "15 Dias", "30 Dias", "90 Dias", "120 Dias", "180 Dias"],
      entidades: {
        FNS: {
          emisor: 'emisor',
          receptor: 'sujetoExcluido'
        },
        CDD: {
          emisor: 'donatario',
          receptor: 'donante'
        }
      }
    }
  },
  async created() {
    try {
      if(!this.$route.params.code || !this.$route.params.key) throw new Error('Debe ingresar la URL con las credenciales correspondientes, vuelva a intentarlo más tarde.')

      const apiRes = await this.$API.invoices.getInvoiceData(this.$route.params.code, this.$route.params.key);
      if(apiRes.success) {
        this.data = await apiRes.data;
        this.managePrint();
      }
      
    } catch (error) {
      this.loading = false;
      this.fetchingError = error.message || 'Se ha producido un error con la verificación de las credenciales de la URL, inténtelo de nuevo más tarde.';
      this.showInvoice = false;
    }
  },
  mounted() {
    //console.log(this.print);
  },
  methods: {
    async managePrint() {
      const { type , id } = this.$route.params;
      const entidadEmisora = this.entidades[ type ] ? this.entidades[ type ].emisor : 'emisor' ;
      const entidadReceptora = this.entidades[ type ] ? this.entidades[ type ].receptor : 'receptor' ;
      //console.log( type , id , code , key);
      this.assignBody( this.data.invoice.dteJson.cuerpoDocumento, type )
      this.assignSummary( this.data.invoice.dteJson.resumen, type )

      const iva = this.data.invoice.dteJson.resumen.tributos.find( current => current.codigo === '20' );

      this.data.invoice.dteJson.resumen.totalIva = iva ? iva?.valor : 0;

      this.data.invoice.dteJson.cuerpoDocumento
      this.print = {
        header: {
          businessName: this.data.invoice.dteJson[ entidadEmisora ].nombre,
          businessComercialName: this.data.invoice.dteJson[ entidadEmisora ].nombreComercial,
          businessActivity: this.data.invoice.dteJson[ entidadEmisora ].descActividad.concat(' (', this.data.invoice.dteJson[ entidadEmisora ].codActividad, ')'),
          businessAddress: this.data.invoice.dteJson[ entidadEmisora ].direccion.complemento,
          //Agregar pais, departamento y municipio
          businessPhone: this.data.invoice.dteJson[ entidadEmisora ].telefono,
          businessMobile: this.data.invoice.dteJson[ entidadEmisora ].celular || this.data.invoice.dteJson[ entidadEmisora ].movil || '',
          businessEmail: this.data.invoice.dteJson[ entidadEmisora ].correo,
          businessNIT: this.data.invoice.dteJson[ entidadEmisora ].nit,
          businessNRC: this.data.invoice.dteJson[ entidadEmisora ].nrc,

          invoiceMHEnv: this.data.invoice.dteJson.identificacion.ambiente,
          invoiceCodGen: this.data.responseMH.codigoGeneracion,
          invoiceSelloRecepcion: this.data.responseMH.selloRecibido,
          invoiceNumControl: this.data.invoiceDTE,
          invoiceDate: this.data.responseMH.fhProcesamiento.slice(0, 10),
          invoiceType: type,
          invoiceID: id,

          invoiceModel: this.data.invoice.dteJson.identificacion.tipoModelo,
          invoiceOperation: this.data.invoice.dteJson.identificacion.tipoOperacion,
          invoiceMoney: this.data.invoice.dteJson.identificacion.tipoMoneda,
          invoiceTypeDte: this.data.invoice.dteJson.identificacion.tipoDte,
          invoicePayment: this.data.invoice.dteJson.resumen.pagos ? this.paymentMethod[parseInt(this.data.invoice.dteJson.resumen.pagos[0].codigo)] : '',

          invoiceDocRelated: this.data.invoice.dteJson.documentoRelacionado,
          invoiceDocs: this.data.invoice.dteJson.otrosDocumentos,

          invoiceName: this.data.invoice.dteJson[ entidadReceptora ].nombre,
          invoiceAddress: this.data.invoice.dteJson[ entidadReceptora ].direccion?.complemento || this.data.invoice.dteJson[ entidadReceptora ].complemento,
          //Agregar pais, departamento, municipio
          invoiceTypeDoc: this.data.invoice.dteJson[ entidadReceptora ].TipoDocumento,
          invoiceNumDoc: this.data.invoice.dteJson[ entidadReceptora ].numDocumento,
          invoiceNIT: this.data.invoice.dteJson[ entidadReceptora ].nit,
          invoiceNRC: this.data.invoice.dteJson[ entidadReceptora ].nrc,
          invoiceEntity: this.data.invoice.dteJson[ entidadReceptora ].nombreComercial,
          invoiceEntityActivity: this.data.invoice.dteJson[ entidadReceptora ].descActividad?.concat(' (', this.data.invoice.dteJson[ entidadEmisora ].codActividad, ')'),
        },
        body: this.data.invoice.dteJson.cuerpoDocumento,
        resume: this.data.invoice.dteJson.resumen,
        jsonToExport: this.data.invoice.dteJson,
      };
      switch (this.data.invoice.dteJson.resumen.condicionOperacion) {
        case 1:
          // cancelada
          this.print.header.invoiceStatus = this.invoiceStatus[1];
          break;
        case 2:
          // credito
          this.print.header.invoiceStatus = this.invoiceStatus[2];
          break;
        default:
          // pendiente de pago
          this.print.header.invoiceStatus = this.invoiceStatus[0];
          break;
      }
      this.loading = false;
      this.showInvoice = true;
      //console.log(this.print);
    },
    assignBody( body, invoiceType ) {
      for( const index in body ) {
        const { ventaExenta, ventaNoSuj, ventaGravada } = body[ index ];
        body[ index ].ventaExenta = ventaExenta ? ventaExenta : 0 ;
        body[ index ].ventaNoSuj = ventaNoSuj ? ventaNoSuj : 0 ;
        body[ index ].ventaGravada =
          ventaGravada ?
            ventaGravada :
            invoiceType === 'FNS' ? 
              body[ index ].compra :
              0 ;
      }
    },
    assignSummary( summary, invoiceType ) {
      const { subTotalVentas } = summary;
      summary.subTotalVentas =
        subTotalVentas ?
          subTotalVentas :
          invoiceType === 'FNS' ? 
            summary.subTotal :
            0 ;
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
}

</style>
